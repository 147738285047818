import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from '@getvim/translate';
import { Text, AlignEnum, ColorNameEnum, SizeEnum, WeightEnum } from '@getvim/atomic-ui';
import Formatter from '@getvim/components-utils-formatter';
import { searchActionAnalytics } from '../api';
import { NonEmptyString } from '../models/utils';
import ResultCard from './resultCard/ResultCard';
import { Language } from '../models/Language';
import { OnBook } from './bookButtonBySdk/BookButtonBySdk';
import { ProviderType } from '../models/Provider';
import { withAnalyticsProp } from '../utils/analytics';

const IN_CARETEAM_QUERY_ID = 'IN_CARETEAM';
const PROVIDER_RANKING = 1;

export type CareTeamProps = withAnalyticsProp &
  OnBook & {
    provider: ProviderType;
    careTeam: ProviderType[];
    language: Language;
    brokerId?: NonEmptyString;
  };

export const CareTeam: FunctionComponent<CareTeamProps> = ({
  provider,
  careTeam,
  language,
  brokerId,
  onBook,
  analytics,
}) => {
  const intl = useIntl();

  return (
    <div className="provider-search-results">
      {!careTeam?.length ? null : (
        <section className="care-team">
          <div className="padding-box-30">
            <Text
              align={AlignEnum.left}
              colorName={ColorNameEnum.theme}
              size={SizeEnum['18px']}
              weight={WeightEnum.bold}
            >
              {intl.formatMessage(
                { id: 'pages.providerDetailsPage.careTeamTitle' },
                {
                  name: Formatter.formatProviderTitle({
                    ...provider,
                    suffix: null,
                  }),
                },
              )}
            </Text>
            <Text align={AlignEnum.left} colorName={ColorNameEnum.dark} size={SizeEnum['14px']}>
              <FormattedMessage
                id="pages.providerDetailsPage.careTeamExplain"
                values={{
                  br: <br />,
                }}
              />
            </Text>
          </div>
          {careTeam.map((teamMember) => (
            <div className={`care-team-member-${teamMember.npi}`} key={teamMember.npi}>
              <ResultCard
                provider={teamMember}
                language={language}
                queryId={IN_CARETEAM_QUERY_ID}
                brokerId={brokerId}
                analyticsMetadata={analytics.metadata}
                onBook={({ appointmentId, bookingType, ...payload }) => {
                  const [location] = provider.locations;
                  searchActionAnalytics({
                    actionType: bookingType!,
                    npi: provider.npi,
                    locationId: location.id,
                    queryId: IN_CARETEAM_QUERY_ID,
                    ranking: PROVIDER_RANKING,
                    entityId: appointmentId,
                  }).then(() => {
                    if (onBook) {
                      onBook(payload);
                    }
                  });
                }}
              />
            </div>
          ))}
        </section>
      )}
    </div>
  );
};
