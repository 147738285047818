import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@getvim/components-hooks-use-theme';
import {
  BookingType,
  Button,
  deprecated,
  DistanceChip,
  ResultCard as ResultCardUI,
  Availability,
  AvailabilitySizeEnum,
} from '@getvim/atomic-ui';
import Formatter from '@getvim/components-utils-formatter';
import { useIntl } from '@getvim/translate';
import useSdkEvents from '@getvim/components-hooks-use-sdk-events';
import VimSdkClient from '@getvim/utils-vim-sdk-client';
import useQueryString from '@getvim/components-hooks-use-query-string';
import { ProviderType } from '../../models/Provider';
import BookButtonBySdk, { OnBook } from '../bookButtonBySdk/BookButtonBySdk';
import { Language } from '../../models/Language';
import { NonEmptyString } from '../../models/utils';
import logger from '../../utils/logger';
import reactNativeEvent from '../../utils/reactNativeEvent';

import './index.less';

type ResultCardProps = OnBook & {
  provider: ProviderType;
  onProviderDetailsClick?: () => void;
  onBookClick?: (params: { npi: string; address: string }) => void;
  language: Language;
  queryId: string;
  brokerId?: NonEmptyString;
  analyticsMetadata?: any;
  shouldDisplayDoctorProfile?: boolean;
  shouldNavigateToProfileUsingSdkEvent?: boolean;
};

const ResultCard: FunctionComponent<ResultCardProps> = ({
  provider,
  language,
  brokerId,
  onBook,
  onProviderDetailsClick,
  onBookClick,
  queryId,
  analyticsMetadata,
  shouldDisplayDoctorProfile,
  shouldNavigateToProfileUsingSdkEvent,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const sdkEvents = useSdkEvents();
  const [location] = provider.locations;
  const taxonomies = Formatter.formatTaxonomies(provider.taxonomies);
  const fullProviderName = Formatter.formatProviderTitle(provider);
  const languagesStringArray = provider.languages.map((lang) => lang.name);
  const distance = location.distance ? Math.round(location.distance * 10) / 10 : undefined;
  const profileUrl = location.implementationSpecificFields?.webpage;
  const { memberToken: memberTokenFromQS } = useQueryString();
  const memberToken = memberTokenFromQS ? (memberTokenFromQS as string) : undefined;
  const [isLoading, setIsLoading] = useState(true);
  const [bookingType, setBookingType] = useState<BookingType>(BookingType.NONE);

  useEffect(() => {
    const getAvailability = async () => {
      const availability = await VimSdkClient.getProviderAvailability(
        [
          {
            npi: provider.npi,
            address: provider.locations[0].address,
          },
        ],
        {
          memberToken,
        },
      ).catch((error) => {
        logger.error(error.message);

        return null;
      });

      setBookingType(
        availability ? (BookingType as any)[availability[0].bookingType] : BookingType.NONE,
      );
      setIsLoading(false);
    };

    getAvailability();
  }, [provider.npi, provider.locations, memberToken]);

  // This function will return a search query in this format: ?utm_XXX=YYYY&utm_ZZZ=YYYY
  const extractUTMQueryParams = () => {
    try {
      const utmParams = Object.keys(analyticsMetadata?.utm);
      const query = utmParams.reduce(
        (partQuery, param) => `${partQuery}${param}=${analyticsMetadata.utm[param]}&`,
        '',
      );

      return query.length ? `?${query.slice(0, -1)}` : '';
    } catch (error) {
      return '';
    }
  };

  return (
    <ResultCardUI
      className="provider-discovery-result-card"
      id={provider.npi}
      key={provider.npi}
      horizontalActionButtons
      resultImg={<deprecated.ProviderLogo provider={provider} isHighValueBadgeEnable />}
      resultMainText={fullProviderName}
      resultMainIcon={
        location.implementationSpecificFields?.telehealth ? (
          <i className="icon-video-cam telehealth-icon" style={{ color: theme.accentColor }} />
        ) : undefined
      }
      resultSecondaryText={taxonomies}
      addressTitle={location.officeName}
      address={location.address}
      locationDistance={<DistanceChip distance={distance} />}
      primaryActionBtn={
        <div className="sdk-book-button-section">
          <BookButtonBySdk
            npi={provider.npi}
            address={provider.locations[0].address}
            language={language}
            analyticsMetadata={{ ...analyticsMetadata, brokerId, queryId }}
            isLoading={isLoading}
            bookingType={bookingType}
            onBook={onBook}
            onBookClick={onBookClick}
            bgColor="themedOutline"
            buttonType="small"
          />
          <span
            data-qa-gender={provider.gender}
            data-qa-language={languagesStringArray}
            style={{ display: 'none' }}
          />
        </div>
      }
      secondaryActionBtn={
        profileUrl && (shouldDisplayDoctorProfile || shouldNavigateToProfileUsingSdkEvent) ? (
          <Button
            onClick={(e: Event) => {
              e.stopPropagation();
              if (shouldNavigateToProfileUsingSdkEvent) {
                sdkEvents.viewProfile({
                  url: profileUrl + extractUTMQueryParams(),
                  npi: provider.npi,
                  address: provider.locations[0].address,
                });
                reactNativeEvent({
                  event: 'viewProfile',
                  payload: {
                    url: profileUrl + extractUTMQueryParams(),
                    npi: provider.npi,
                    address: provider.locations[0].address,
                  },
                });
              } else {
                window.open(profileUrl + extractUTMQueryParams(), '_blank');
              }
              if (onProviderDetailsClick) {
                onProviderDetailsClick();
              }
            }}
            bgColor="themedOutline"
            buttonType="small"
          >
            {intl.formatMessage({ id: 'general.viewProfile' })}
          </Button>
        ) : undefined
      }
      verticalMode
      showLocation
      showShadow={false}
      showNewBookButton
    >
      <Availability
        acceptNewPatients={provider.acceptNewPatients || location.acceptNewPatients}
        size={AvailabilitySizeEnum.tiny}
      />
    </ResultCardUI>
  );
};

export default ResultCard;
